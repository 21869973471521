import React, {Fragment, ReactElement, useEffect, useState} from 'react';
import {IUser} from "./types/entities/user";
import {IMetaData} from "./types/layout";
import {Context} from "./context";
import {CToaster} from "@coreui/react-pro";
import {BrowserRouter, Routes} from "react-router-dom";
import AdminRoutes from "./admin/AdminRoutes";
import ClientRoutes from "./client/ClientRoutes";
import useReloader from "./hooks/reloader";

function App() {
    const [contextToast, setcontextToast] = useState<ReactElement | undefined>()
    const [contextUser, setContextUser] = useState<IUser>()

    const [contextVisibleModalLogin, setContextVisibleModalLogin] = useState(localStorage.getItem('token') == null || localStorage.getItem('token') === undefined)
    const [contextVisibleModalRefresh, setContextVisibleModalRefresh] = useState(false)
    const [contextVisibleModalLogout, setContextVisibleModalLogout] = useState(false)

    const [contextMetaData, setContextMetaData] = useState<IMetaData>({})
    const {notification, reload} = useReloader()

    useEffect(() => {
        document.title = `${contextMetaData.title ? `${contextMetaData.title} | ` : ''}${process.env.REACT_APP_PUBLIC_SITE_NAME}`
    }, [contextMetaData.title])

    return (<>
        <Context.Provider value={{
            user: contextUser,
            setUser: setContextUser,
            setToast: setcontextToast,
            setMetaData: setContextMetaData,
            visibleModalLogin: contextVisibleModalLogin,
            setVisibleModalLogin: setContextVisibleModalLogin,
            visibleModalRefresh: contextVisibleModalRefresh,
            setVisibleModalRefresh: setContextVisibleModalRefresh,
            visibleModalLogout: contextVisibleModalLogout,
            setVisibleModalLogout: setContextVisibleModalLogout,
            reloadQuestionnaire :reload,
            reloadQuestionnaireNotification: notification
        }}>
            <React.StrictMode>
                <BrowserRouter>
                    <Routes>
                        {AdminRoutes.map((x, key) => {return <Fragment key={key}>{x}</Fragment>})}
                        {ClientRoutes.map((x, key) => {return <Fragment key={key}>{x}</Fragment>})}
                    </Routes>
                </BrowserRouter>
            </React.StrictMode>
        </Context.Provider>
        <CToaster push={contextToast} placement="top-end" />
    </>)
}

export default App;
