import {useContext, useEffect, useState} from "react";
import {Context} from "../../../../../context";
import GeneralInformation from "./GeneralInforamation";
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCardTitle,
    CCol, CForm, CFormCheck,
    CLoadingButton, CModal, CModalBody, CModalHeader, CModalTitle,
    CRow
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {cilCaretLeft, cilCaretRight, cisCaretLeft, cisCaretRight} from "@coreui/icons-pro";
import CasePart1 from "./CasePart1";
import CasePart2 from "./CasePart2";
import CasePart3 from "./CasePart3";
import useToast from "../../../../../hooks/toast";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import api from "../../../../api";
import {GenericErrorHandler} from "../../../../../helpers";


export default function Case(){
    const [page, setPage] = useState(1)

    return (<CCard>
        <CCardBody>
            <CCardTitle>
                <CRow>
                    <CCol>Case ({page}/4)</CCol>
                    <CCol className={'d-flex justify-content-end'}>
                        <CButton disabled={page <= 1} size={"sm"} onClick={() => {
                            setPage(x => {
                                const newpage = x - 1;
                                return newpage <= 1 ? 1 : newpage;
                            })
                        }} className={'ms-2'}><CIcon icon={cilCaretLeft}/></CButton>
                        <CButton disabled={page >= 4} size={"sm"} onClick={() => {
                            setPage(x => {
                                const newpage = x + 1;
                                return newpage >= 4 ? 4 : newpage;
                            })
                        }} className={'ms-2'}><CIcon icon={cilCaretRight}/></CButton>
                    </CCol>
                </CRow>
            </CCardTitle>
            {page === 1 && <GeneralInformation/>}
            {page === 2 && <CasePart1/>}
            {page === 3 && <CasePart2/>}
            {page === 4 && <CasePart3/>}
        </CCardBody>

    </CCard>)
}

