import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect } from "@coreui/react-pro"
import { createSearchParams, useSearchParams } from "react-router-dom"
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import ComponentDatePicker from "../../../componenets/DatePicker";
import moment from "moment";


const FilterSorting = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (<CForm className='row gx-3 gy-2 align-items-center'>
        <CCol lg={3}>
            <CFormInput
                type="text"
                placeholder='Nama'
                value={searchParams.get("name") || ''}
                size='sm'
                aria-label="Name"
                label={"Name"}
                onChange={(e => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === undefined || e.target.value === "") newsp.delete('name')
                    else newsp.set('name', e.target.value || "")
                    setSearchParams(newsp)
                })}
            />
        </CCol>

        <CCol lg={2} xs={6}>
            <CFormLabel>Created After</CFormLabel>
            <ComponentDatePicker
                id={'created_at_after'}
                placeholder={'Created After'}
                selected={moment(searchParams.get("created_at_after"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("created_at_after") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('created_at_after')
                    else newsp.set('created_at_after', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Created Before</CFormLabel>
            <ComponentDatePicker
                id={'created_at_before'}
                placeholder={'Created Before'}
                selected={moment(searchParams.get("created_at_before"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("created_at_before") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('created_at_before')
                    else newsp.set('created_at_before', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Group</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('group') || ''}
                options={[
                    { label: 'Semua', value: "" },
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "") newsp.delete('group')
                    else newsp.set('group', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>
        <CCol lg={3} xs={6}>
            <CFormLabel>Sort By</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('ordering') || 'id'}
                options={[
                    { label: '# (asc)', value: 'id' },
                    { label: '# (desc)', value: '-id' },
                    { label: 'Name (asc)', value: 'name' },
                    { label: 'Name (desc)', value: '-name' },
                    { label: 'Group (asc)', value: 'group' },
                    { label: 'Group (desc)', value: '-group' },
                    { label: 'Created At (asc)', value: 'created_at' },
                    { label: 'Created At (desc)', value: '-created_at' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "id") newsp.delete('ordering')
                    else newsp.set('ordering', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>

        <CCol lg={12}>
            <CButton className="me-2 mt-2" size="sm" color="secondary" onClick={() => {
                setSearchParams(createSearchParams())
            }}>Reset</CButton>
        </CCol>
    </CForm>)
}

export default FilterSorting