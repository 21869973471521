import {
    CButton,
    CCard,
    CCardBody, CCardFooter,
    CCardHeader,
    CCardTitle, CCol,
    CFooter,
    CForm,
    CFormInput,
    CFormLabel, CFormSelect, CLoadingButton, CRow
} from "@coreui/react-pro";
import { z } from 'zod';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import {zodResolver} from "@hookform/resolvers/zod";
import {useContext, useEffect} from "react";
import api from "../../../../api";
import useToast from "../../../../../hooks/toast";
import {GenericErrorHandler} from "../../../../../helpers";
import {Context} from "../../../../../context";

export default function InformasiDiri(){
    const { reloadQuestionnaire } = useContext(Context);
    const [addToast] = useToast()
    const FormSchema = z.object({
        nama: z.string().min(1, 'Nama tidak boleh kosong'),
        jenis_kelamin: z.string().min(1, 'Jenis kelamin harus dipilih'),
        usia: z.string().min(1, 'Usia tidak boleh kosong'),
        pendidikan_terakhir: z.string().min(1, 'Pendidikan Terakhir tidak boleh kosong'),
        instansi: z.string().min(1, 'Instansi tidak boleh kosong'),
        jabatan: z.string().min(1, 'Jabatan tidak boleh kosong'),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        getValues,
        control,
        reset,
        watch,
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: { ...JSON.parse(localStorage.getItem('informasidiri') || "{}")}
    })

    const instance = watch()

    useEffect(() => {
        localStorage.setItem('informasidiri', JSON.stringify(instance))
    }, [instance])



    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post('/answer', {section : 'informasidiri', answer : JSON.stringify(instance)}).then(onfulfilled => {
            addToast({message: 'Section submitted', color: 'success'})
            reloadQuestionnaire()
            localStorage.removeItem('informasidiri')
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }

    return (<CCard
    >
        <CCardBody>
            <CCardTitle>Informasi Diri</CCardTitle>
            <p>Mohon memberikan informasi yang akurat untuk semua kolom informasi yang tersedia.</p>
            <CForm
                id='formSubmit'
                className="row"
                autoComplete='false'
                onSubmit={handleSubmit(onSubmit)}
            >
                <CFormLabel htmlFor="nama" className="col-md-2 col-form-label">Nama</CFormLabel>
                <CCol md={10} className='mb-3' >
                    <CFormInput
                        type="text"
                        placeholder='Nama'
                        invalid={errors.nama != null}
                        feedbackInvalid={errors.nama?.message as string}
                        {...register("nama")}
                    />
                </CCol>
                <CFormLabel htmlFor="jenis_kelamin" className="col-md-2 col-form-label">Jenis kelamin</CFormLabel>
                <CCol md={10} className='mb-3' >
                    <CFormSelect
                        invalid={errors.jenis_kelamin != null}
                        feedbackInvalid={errors.jenis_kelamin?.message as string}
                        {...register("jenis_kelamin")}
                        options={[
                            { label: 'Pilih jenis kelamin', value: "" },
                            { label: 'Pria', value: 'Pria' },
                            { label: 'Wanita', value: 'Wanita' },
                        ]}
                    />
                </CCol>
                <CFormLabel htmlFor="usia" className="col-md-2 col-form-label">Usia</CFormLabel>
                <CCol md={10} className='mb-3' >
                    <CFormInput
                        type='number'
                        placeholder='Usia'
                        invalid={errors.usia != null}
                        feedbackInvalid={errors.usia?.message as string}
                        {...register("usia")}
                        min={0}
                    />
                </CCol>
                <CFormLabel htmlFor="pendidikan_terakhir" className="col-md-2 col-form-label">Pendidikan terakhir</CFormLabel>
                <CCol md={10} className='mb-3' >
                    <CFormInput
                        placeholder='Pendidikan Terakhir'
                        invalid={errors.pendidikan_terakhir != null}
                        feedbackInvalid={errors.pendidikan_terakhir?.message as string}
                        {...register("pendidikan_terakhir")}
                    />
                </CCol>
                <CFormLabel htmlFor="instansi" className="col-md-2 col-form-label">Instansi</CFormLabel>
                <CCol md={10} className='mb-3' >
                    <CFormInput
                        placeholder='Instansi'
                        invalid={errors.instansi != null}
                        feedbackInvalid={errors.instansi?.message as string}
                        {...register("instansi")}
                    />
                </CCol>
                <CFormLabel htmlFor="jabatan" className="col-md-2 col-form-label">Jabatan</CFormLabel>
                <CCol md={10} className='mb-3' >
                    <CFormInput
                        placeholder='Jabatan'
                        invalid={errors.jabatan != null}
                        feedbackInvalid={errors.jabatan?.message as string}
                        {...register("jabatan")}
                    />
                </CCol>
            </CForm>
            <div className={'d-flex justify-content-end'}>
                <CButton className={'ms-2'} color={'danger'} onClick={() => {
                    reset()
                }}>Reset</CButton>
                <CLoadingButton disabled={isSubmitting} loading={isSubmitting} type={'submit'} form={'formSubmit'} className={'ms-2'}>Selanjutnya</CLoadingButton>
            </div>
        </CCardBody>
    </CCard>)
}