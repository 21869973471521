import React, {useContext, useEffect} from 'react';
import Header from "./layout/header";
import {Outlet} from "react-router-dom";
import api from "./api";
import useToast from "../hooks/toast";
import {GenericErrorHandler} from "../helpers";
import {IToken} from "../types/token";
import {CContainer} from "@coreui/react-pro";
import {Context} from "../context";
import {ModalLogin} from "./layout/modal.login";
import {ModelRefresh} from "./layout/modal.refresh";
import {ModalLogout} from "./layout/modal.logout";

function App() {
  const { setUser, setVisibleModalLogin, setVisibleModalRefresh, setMetaData } = useContext(Context);
  const [addToast] = useToast()

  useEffect(() => {
    api.get('/auth/me').then(onfulfilled => {
      setUser(onfulfilled.data)
    }).catch(reason => {
      GenericErrorHandler({reason, addToast})
    })
  }, [addToast, setUser])

  useEffect(() => {
    const interval = setInterval(async () => {
      const token : IToken = JSON.parse(localStorage.getItem('token') || JSON.stringify("{}"));

      if(!token.token){
        setVisibleModalLogin(true)
      }else{
        if(token.expires_at != null){
          const diff = new Date(token?.expires_at || new Date().toString()).getTime() - new Date().getTime();

          if (diff <= (1000*60*10)){//10 minutes
            setVisibleModalLogin(true)
          }else if(diff > 0 && diff < (1000*60*30)){ // 30 minutes
            setVisibleModalRefresh( true)
            await api.get(`/auth/refresh`).then(async onfulfilled =>{
              localStorage.setItem('token', JSON.stringify(onfulfilled.data))
              addToast({ message: "Token Refreshed", color: "success" })

            }).catch(reason => {
              GenericErrorHandler({reason, addToast})
              localStorage.clear()
            }).finally(() => {
              setVisibleModalRefresh(false)
            })
          }else{
            setVisibleModalLogin(false)
            setVisibleModalRefresh(false)
          }
        }else{
          setVisibleModalLogin(false)
          setVisibleModalRefresh(false)
        }
      }

    }, 1000);

    return () => clearInterval(interval);
  }, [addToast, setVisibleModalLogin, setVisibleModalRefresh]);

  // useEffect(() => {
  //   setMetaData({title: "", description: "Questioner"})
  // }, [setMetaData])

  return (<div className="wrapper d-flex flex-column min-vh-100 bg-light">
    <Header />
    <div className="body flex-grow-1 px-3">
      <CContainer xl className='pb-4'>
        <Outlet />
      </CContainer>
    </div>
    <ModalLogin/>
    <ModelRefresh/>
    <ModalLogout/>
  </div>)
}

export default App;
