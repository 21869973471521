import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol, CPlaceholder,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell, CTableHead, CTableHeaderCell,
    CTableRow
} from '@coreui/react-pro'
import { useParams } from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import api from "../../api";
import useToast from "../../../hooks/toast";
import {IRespondent} from "../../../types/entities/respondent";
import {IAnswer} from "../../../types/entities/answer";
import {Context} from "../../../context";

const Detail = () => {
    const { setMetaData } = useContext(Context)
    const params = useParams();
    const title = `Detail Respondent #${params.id}`
    const [addToast] = useToast()
    const [respondent, setRespondent] = useState<IRespondent>({ user: undefined});
    const [answers, setAnswers] = useState<IAnswer[]>([])
    const [isLoadingRespondent, setIsLoadingRespondent] = useState(true)
    const [isLoadingAnswers, setIsLoadingAnswers] = useState(true)


    useEffect(() => {
        setMetaData({ title })

        api.get(`/respondent/${params.id}`).then(onfulfilled => {
            setRespondent(onfulfilled.data)
            setIsLoadingRespondent(false)
        }).catch(reason => {
            if (reason.code === "ERR_NETWORK") {
                addToast({ message: reason.message, color: "danger" })
            } else {
                try {
                    const dataError = reason.response.data;
                    addToast({ message: dataError["message"], color: "danger" })
                } catch {
                    addToast({ message: "Undefined Error", color: "danger" })
                }
            }
        })

        api.get(`/answer?respondent_id=${params.id}`).then(onfulfilled => {
            setAnswers(onfulfilled.data)
            setIsLoadingAnswers(false)
        }).catch(reason => {
            if (reason.code === "ERR_NETWORK") {
                addToast({ message: reason.message, color: "danger" })
            } else {
                try {
                    const dataError = reason.response.data;
                    addToast({ message: dataError["message"], color: "danger" })
                } catch {
                    addToast({ message: "Undefined Error", color: "danger" })
                }
            }
        })
    }, [setMetaData, title])

    return (<>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader >
                        <strong>{title}</strong>
                    </CCardHeader>
                    <CCardBody>
                        {isLoadingRespondent && <CPlaceholder xs={12} size="lg" />}
                        {!isLoadingRespondent && <CTable responsive hover striped>
                            <CTableBody>
                                <CTableRow>
                                    <CTableDataCell>Name</CTableDataCell>
                                    <CTableDataCell><strong>{respondent.user?.name}</strong></CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>Group</CTableDataCell>
                                    <CTableDataCell><strong>{respondent.group}</strong></CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>Created At</CTableDataCell>
                                    <CTableDataCell><strong>{respondent.created_at?.toString()}</strong></CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        </CTable>}
                        {isLoadingAnswers && <CPlaceholder xs={12} size="lg" />}
                        {!isLoadingAnswers && <CTable className={'mt-5'} responsive striped hover>
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell>Section</CTableHeaderCell>
                                    <CTableHeaderCell>Answer</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {!answers.length && <CTableRow>
                                    <CTableDataCell colSpan={2} className={'text-center'}>(Data tidak ditemukan)</CTableDataCell>
                                </CTableRow>}

                                { answers.map((answer, key) => {
                                    return (<CTableRow key={key}>
                                        <CTableDataCell>{answer.section}</CTableDataCell>
                                        <CTableDataCell>{answer.answer}</CTableDataCell>
                                    </CTableRow>)
                                }) }
                            </CTableBody>
                        </CTable>}

                    </CCardBody>
                </CCard>

            </CCol>
        </CRow>
    </>)
}

export default Detail