export default function Page5({Question}: {Question: ({question, name}: {question: string, name: "q1" | "q2" | "q3" | "q4" | "q5" | "q6" | "q7" | "q8" | "q9" | "q10" | "q11" | "q12" | "q13" | "q14" | "q15" | "q16" | "q17" | "q18" | "q19" | "q20" | "q21" | "q22" | "q23" | "q24"}) =>    JSX.Element}){

    const questions: {name: string, question: string}[] = [
        {name: 'q19', question: 'Pada dasarnya hanya ada dua jenis orang di dunia ini yaitu orang benar yang akan diberi pahala oleh Tuhan, dan sisanya yang tidak'},
        {name: 'q20', question: 'Kitab suci mungkin berisi kebenaran umum, tetapi tidak boleh dianggap sepenuhnya benar secara harfiah'},
        {name: 'q21', question: 'Untuk menjalani kehidupan yang terbaik dan paling bermakna, seseorang harus menganut satu agama yang secara fundamental benar'},
        {name: 'q22', question: '"Setan" hanyalah nama yang diberikan orang-orang untuk keinginan buruk mereka. Sebenarnya tidak ada suatu kejahatan semacam pangeran kegelapan yang menggoda kita'},
        {name: 'q23', question: 'Kapanpun ketika ilmu pengetahuan dan kitab suci bertentangan maka kitab sucilah yang selalu benar'},
        {name: 'q24', question: 'Dasar-dasar keTuhanan beragama seharusnya tidak boleh dicampuradukkan atau dikompromikan dengan keyakinan orang lain'},
    ]

    return (<>
        <ol start={19}>
            {questions.map((quest,key) => {
                return(<li key={key}>
                    <Question name={quest.name as 'q19' | 'q20' | 'q21'| 'q22'| 'q23'| 'q24'} question={quest.question} />
                </li>)
            })}
        </ol>

    </>)
}