import {CCol, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableRow} from "@coreui/react-pro";

export default function GeneralInformation(){

    return (<CRow>
        <CCol md={6}>
            <p className="h4">Financial Performance 2023 (Current Condition)</p>
            <p><strong>1.1. Informasi Umum</strong></p>
            <p>
                <strong>Informasi Umum</strong><br/>
                Ad&At Bank beroperasi di Indonesia, dengan kapasitas total assets sebesar 14 triliun IDR. Bank ini
                memiliki beberapa anak perusahaan yang bergerak di berbagai bidang usaha. Sementara itu, sebagai
                perusahaan induk, bank ini bertujuan secara umum untuk mencari laba yang memadai dengan <strong>target
                profit margin minimum di angka 3% dari total deposit, dan minimum target yang harus dicapai adalah 80%,
                dengan konsekuensi</strong>:

            </p>
            <ul>
                <li><strong>apabila hanya tercapai 60-80%, jabatan anda sebagai CEO akan dievaluasi kembali</strong>
                </li>
                <li>dan <strong>jika di bawah 60%, langsung diganti</strong></li>
            </ul>
            <p>
                Dengan demikian, kinerja Ad&At Bank Tahun 2023 (Triwulan) dalam ringkasan kondisi aktual atas laporan
                laba/rugi yang masih berjalan.
            </p>
        </CCol>
        <CCol md={6}>
            <CTable striped={true} hover={true} bordered={true} items={[
                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan IDR)'},
                {c1: 'Pendapatan bugna kredit', c2: '', c3: '44.875'},
                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                {c1: 'Beban operasi bisnis', c2: '31.675', c3: ''},
                {c1: <strong>Ekspektasi Profit Margin. Menggnuakan Minimum (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                {c1: 'Total beban dikeluarkan', c2: '', c3: '45.625'},
                {c1: 'EBIT', c2: '', c3: <span className={'text-danger'}>-750</span>},
            ]}/>
        </CCol>
    </CRow>)
}