
import { useContext, useEffect, useState} from "react";
import {Context} from "../../../../../context";
import {
    CButton,
    CCol,
    CForm,
    CFormCheck,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CRow
} from "@coreui/react-pro";
import {z} from "zod";
import useToast from "../../../../../hooks/toast";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import CIcon from "@coreui/icons-react";
import {cilCheck, cisCheck} from "@coreui/icons-pro";
import api from "../../../../api";
import {GenericErrorHandler} from "../../../../../helpers";


export default function CasePart3(){
    const [showModalConfirmation, setShowModalConfirmation] = useState(false)

    const { user } = useContext(Context);
    const { reloadQuestionnaire } = useContext(Context);
    const [addToast] = useToast()
    const FormSchema = z.object({
        kebijakan: z.enum(["1","2","3","4"]),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        getValues,
        control,
        reset,
        watch,
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: { ...JSON.parse(localStorage.getItem('case') || "{}")}
    })

    const instance = watch()

    useEffect(() => {
        localStorage.setItem('case', JSON.stringify(instance))

    }, [instance])

    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {

        await api.post('/answer', {section : 'case', answer : JSON.stringify(instance)}).then(onfulfilled => {
            addToast({message: 'Section submitted', color: 'success'})
            localStorage.removeItem('case')
            reloadQuestionnaire()
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }
    return (<>
        <p>Pilihlah yang merepresentasikan hasil analisis atau konsekuensi pelaporan atas kebijakan yang Anda ambil sebagai CEO. </p>
        <CForm
            id='formSubmit'
            className="row"
            autoComplete='false'
            onSubmit={handleSubmit(onSubmit)}
        >
            <CCol lg={3} className={'d-grid pb-2'}>
                <CFormCheck
                    label={<strong>Kebijakan 1</strong>}
                    id={`kebijakan_1`}
                    invalid={errors['kebijakan'] != null} feedbackInvalid={errors['kebijakan']?.message as string}
                    {...register('kebijakan', {required: true})}
                    type={"radio"}
                    value={'1'}
                    button={{ color: 'dark' , variant: 'outline', size: 'lg', shape: 'rounded-0' }}
                    onClick={() => { setShowModalConfirmation(true) }}
                />
            </CCol>
            <CCol lg={3} className={'d-grid pb-2'}>
                <CFormCheck
                    label={<strong>Kebijakan 2</strong>}
                    id={`kebijakan_2`}
                    invalid={errors['kebijakan'] != null} feedbackInvalid={errors['kebijakan']?.message as string}
                    {...register('kebijakan', {required: true})}
                    type={"radio"}
                    value={'2'}
                    button={{ color: 'dark' , variant: 'outline', size: 'lg', shape: 'rounded-0' }}
                    onClick={() => { setShowModalConfirmation(true) }}

                />
            </CCol>
            <CCol lg={3}  className={'d-grid pb-2'}>
                <CFormCheck
                    label={<strong>Kebijakan 3</strong>}
                    id={`kebijakan_3`}
                    invalid={errors['kebijakan'] != null} feedbackInvalid={errors['kebijakan']?.message as string}
                    {...register('kebijakan', {required: true})}
                    type={"radio"}
                    value={'3'}
                    button={{ color: 'dark' , variant: 'outline', size: 'lg', shape: 'rounded-0' }}
                    onClick={() => { setShowModalConfirmation(true) }}
                />
            </CCol>
            <CCol lg={3}  className={'d-grid pb-2'}>
                <CFormCheck
                    label={<strong>Kebijakan 4</strong>}
                    id={`kebijakan_4`}
                    invalid={errors['kebijakan'] != null} feedbackInvalid={errors['kebijakan']?.message as string}
                    {...register('kebijakan', {required: true})}
                    type={"radio"}
                    value={'4'}
                    button={{ color: 'dark' , variant: 'outline', size: 'lg', shape: 'rounded-0' }}
                    onClick={() => { setShowModalConfirmation(true) }}
                />
            </CCol>
        </CForm>
        {user?.respondent?.group === 1 && <Group1/>}
        {user?.respondent?.group === 2 && <Group2/>}
        {user?.respondent?.group === 3 && <Group3/>}
        {user?.respondent?.group === 4 && <Group4/>}
        <p>Anda bisa melihat detail kebijakan - kebijakan di halaman sebelum nya.</p>
    </>)

    function ModalKonfirmasi({text} : {text:JSX.Element}){

        return(<CModal alignment='center'  size={'lg'} portal visible={showModalConfirmation} backdrop={'static'} onClose={() => {
            reset({})
        }}>
            <CModalBody>
                <p>
                    <strong>Dengan mempertimbangkan:</strong>
                </p>
                <ol>
                    <li>
                        <strong>Target profit margin min. 3% dari total deposit, dan min. target 80%, dengan
                            konsekuensi:</strong>
                        <ul>
                            <li>apabila <strong>hanya tercapai 60-80%, jabatan anda sebagai CEO akan dievaluasi
                                kembali;</strong>
                            </li>
                            <li>dan <strong>jika di bawah 60%</strong>, langsung diganti.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Posisi Keuangan Perusahaan terakhir (Triwulan):</strong>
                        <ul>
                            <li><strong>EBIT:</strong> <strong className={'text-danger'}>-Rp750.000.000</strong></li>
                        </ul>
                    </li>
                    {text}
                </ol>

                <p className={'text-center mt-5'}>APAKAH ANDA SEBAGAI CEO TELAH YAKIN DENGAN KEBIJAKAN ANDA?</p>
            </CModalBody>
            <CModalFooter>
                <CButton disabled={isSubmitting} size={"lg"} color="danger" onClick={() => {
                    setShowModalConfirmation(false)
                    reset({})
                }}>
                    Analisis Kembali
                </CButton>
                <CLoadingButton size={"lg"} loading={isSubmitting} type={'submit'} form={'formSubmit'}
                                className={'ms-2'}>
                    Setuju dengan Kebijakan {getValues('kebijakan')}
                </CLoadingButton>
            </CModalFooter>
        </CModal>)
    }

    function Group1() {
        return (<ModalKonfirmasi text={<>
            <li>
                <strong>Hasil Revaluasi Aset tahun 2023:</strong> Tanah non-produktif Perusahaan <strong>10Ha dari
                satuan Rp. 120.000/m2 (tahun 2000) menjadi Rp. 760.000/m2 (tahun 2023).</strong>
            </li>
            <li>
                <strong>Perusahaan anak Ad&At Bank, Ad-At-Finance, berencana mengekspansi
                    usahanya</strong> dengan <strong>membuka beberapa kantor cabang dan satu kantor wilayah di
                daerah yang sama dengan tanah non-produktif milik Ad&At Bank yang telah direvaluasi
                tersebut.</strong>
            </li>
        </>}/>)

    }

    function Group2() {
        return (<ModalKonfirmasi text={<>
            <li>
                <strong>Belum membayar iuran BPJS karyawan non-organiknya sejak tahun lalu sampai saat ini (Rp
                    1.352.000.000) & biaya asuransi purna jabatan (Rp 2.450.000.000).
                </strong>
            </li>
            <li>
                <strong>Di triwulan-I pihak manajemen hanya mencapai 0.5% dari 3%. sehingga diminta untuk mengejar
                    ketertinggalan capaian kemarin (2.5%) menjadi 5.5% dari total deposit di triwulan-II ini.
                </strong>
            </li>
            <li>
                <strong>Telah menandatangani kontrak kerjasama dengan Pemerintah untuk menjadi pelopor literasi keuangan
                    nasional selama 5 tahun ke depan (2023-2027) senilai 27 Miliar Rupiah, yang diterima tahun ini
                    sebesar 5.4 Miliar.
                </strong>
            </li>
        </>}/>)

    }

    function Group3() {
        return (<ModalKonfirmasi text={<li>
            <li>
                <strong>Baru saja menandatangani kontrak kerjasama</strong> dengan Pemerintah untuk menjadi <strong>role
                model</strong> sebagai <strong>bank digitazion</strong> kepada masyarakat <strong>selama 3 tahun ke
                depan, senilai 15 Miliar Rupiah dengan penerimaan up-front fee sebesar 20% dari nilai kontrak.</strong>

            </li>
            <li>
                <strong>Belum membayarkan insentif bonus</strong> kepada para <strong>top-middle-down
                management</strong> dan <strong>karyawan
                selama 2 tahun terakhir</strong> karena pengalihan ke laba ditahan dan pengembangan usaha, sebesar Rp
                9.000.000.000
            </li>
        </li>}/>)
    }

    function Group4() {
        return (<ModalKonfirmasi text={<>
            <li>
                <strong>Menandatangani kontrak kerjasama untuk penyediaan fasilitas pengelolaan kredit Perumahan</strong> JOGVIEW dengan <strong>mekanisme sharing profit sebesar 2% dari total plafon kredit 600 Miliar Rupiah selama 15 tahun</strong> ke depan.
            </li>
            <li>
                <strong>
                    Belum membayar tagihan pembayaran jasa outsourcing dari awal tahun ini dengan nilai Rp. 1.645.000.000
                </strong>
            </li>
            <li>
                <strong>
                    Biaya BPJS karyawan non-organik belum terbayarkan sejak tahun lalu (Juli) senilai Rp 945.124.000
                </strong>
            </li>
            <li>
                <strong>
                    Sampai triwulan ke-dua ini termasuk dua tahun berturut-turut belum mencatatkan laba yang lebih tinggi dari 3%.
                </strong>
            </li>
        </>}/>)
    }
}
