export default function Page1({Question}: {Question: ({question, name}: {question: string, name: "q1" | "q2" | "q3" | "q4" | "q5" | "q6" | "q7" | "q8" | "q9" | "q10" | "q11" | "q12" | "q13" | "q14" | "q15" | "q16" | "q17" | "q18" | "q19" | "q20" | "q21" | "q22" | "q23" | "q24"}) =>    JSX.Element}){

    const questions: {name: string, question: string}[] = [
        {name: 'q1', question: 'Seseorang tidak boleh menyakiti orang lain baik secara fisik maupun mental'},
        {name: 'q2', question: 'Orang harus memastikan bahwa tindakan mereka tidak pernah merugikan ornag lain meskipun hanya sedikit'},
        {name: 'q3', question: 'Risiko terhadap orang lain tidak bisa ditoleransi meskipun hanya sedikit'},
        {name: 'q4', question: 'Adanya potensi merugikan orang lain selalu salah, apapun manfaat yang diperoleh'},
        {name: 'q5', question: 'Seseorang tidak boleh melakukan suatu Tindakan yang dapat mengancam martabat dan kesejahteraan orang lain'},
        {name: 'q6', question: 'Apabila suatu perbuatan dapat merugikan seseorang, maka tidak boleh dilakukan'},
    ]

    return (<>
        <ol start={1}>
            {questions.map((quest,key) => {
                return(<li key={key}>
                    <Question name={quest.name as 'q1' | 'q2' | 'q3'| 'q4'| 'q5'| 'q6'} question={quest.question} />
                </li>)
            })}
        </ol>

    </>)
}