import React, {Component, lazy, Suspense, useContext, useEffect, useState} from 'react';
import {

    CModal,
    CModalBody,
    CRow, CSpinner
} from "@coreui/react-pro";

import api from "../../api";
import {GenericErrorHandler} from "../../../helpers";
import useToast from "../../../hooks/toast";
import {IQuestionnaire} from "../../../types/entities/question";
import {Context} from "../../../context";
import Finished from "./finished";
import InformasiDiri from "./sections/informasidiri";
import MengukurKuesioner from "./sections/mengukurkuesioner";
import InstruksiPengerjaan from "./sections/instruksipengerjaan";
import Case from "./sections/case";



export function Questionnaire(){
    const { reloadQuestionnaireNotification } = useContext(Context);
    const [loading, setLoading] = useState(true)
    const [addToast] = useToast()
    const [questionnaire, setQuestionnaire] = useState<IQuestionnaire>()

    useEffect(() => {
        api.get('/questionnaire').then(async onfulfilled => {
            const data: IQuestionnaire = onfulfilled.data
            setQuestionnaire(data)
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }, [addToast, reloadQuestionnaireNotification]);

    if(questionnaire?.finished){
        return (<Finished/>)
    }

    return(<>
        {questionnaire?.section === 'informasidiri' && <InformasiDiri />}
        {questionnaire?.section === 'mengukurkuesioner' && <MengukurKuesioner />}
        {questionnaire?.section === 'instruksipengerjaan' && <InstruksiPengerjaan />}
        {questionnaire?.section === 'case' && <Case />}
    </>)
}
