import axios from "axios";
import {IToken} from "../types/token";

const api = axios.create({
    baseURL: `${process.env.REACT_APP_PUBLIC_API_URL}client`
})

api.interceptors.request.use(
    function (config) {
        const token : IToken = JSON.parse(localStorage.getItem("token") || "{}");
        if (token) config.headers["Authorization"] = `Bearer ${token.token}`
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
//
// api.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const config = error?.config;
//
//         if ([403, 401].includes(error?.response?.status) && !config?.sent) {
//             config.sent = true;
//
//             const currentToken = localStorage.getItem('token');
//
//             if (currentToken == null || currentToken.length === 0){
//                 localStorage.clear()
//                 const wl = window.location
//                 wl.replace(`/login/?returnUrl=${wl.pathname}${wl.search}`)
//                 return Promise.reject(error);
//             }
//
//             let token: IToken = JSON.parse(localStorage.getItem('token') || "{}");
//             if (token.access) config.headers["Authorization"] = `Bearer ${token.refresh}`
//
//             const newToken : string | null = await api.post(`/refresh`).then(response => {
//                 return response.data;
//             }).catch(() => {
//                 return null;
//             });
//
//             if(newToken == null || newToken.length === 0){
//                 localStorage.clear()
//                 const wl = window.location
//                 wl.replace(`/login/?returnUrl=${wl.pathname}${wl.search}`)
//                 return Promise.reject(error);
//             }
//
//             localStorage.setItem('token', newToken)
//             config.headers = {
//                 ...config.headers,
//                 authorization: `Bearer ${localStorage.getItem('token')}`,
//             };
//             return axios(config);
//         }
//
//         return Promise.reject(error);
//     }
// );

export default api;