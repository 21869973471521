import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {IRespondent} from "../../../types/entities/respondent";
import {IPage} from "../../../types/response/pagination";
import {createColumnHelper} from "@tanstack/react-table";
import useToast from "../../../hooks/toast";
import moment from "moment";
import {CCol, CPlaceholder, CRow, CSpinner} from "@coreui/react-pro";
import api from "../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import ComponenetTable from "../../../componenets/Table";

const Table = () => {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<IRespondent>>()
    const [loading, setloading] = useState(true)
    const columnHelper = createColumnHelper<IRespondent>()
    const navigate = useNavigate();
    const [addToast] = useToast()

    const columns = useMemo(() => [
        columnHelper.accessor(row => row.id, {
            id: 'id',
            cell: info => info.getValue(),
            header: () => '#',
        }),
        columnHelper.accessor(row => row, {
            id: 'name',
            cell: info => {
                const row = info.getValue()
                return (<span style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                    {row.user?.name}
                </span>)
            },
            header: () => 'Name',
        }),

        columnHelper.accessor(row => row.group, {
            id: 'group',
            cell: info => {
                return info.getValue()
            },
            header: () => 'Group',
        }),

        columnHelper.accessor(row => row.created_at, {
            id: 'created_at',
            cell: info => {
                return (<>
                    <span className='text-nowrap'>{moment(info.getValue()).format("LLL")}</span>
                    <span className='text-nowrap'><small className='ms-2 text-secondary'>{moment(info.getValue()).fromNow()}</small></span>
                </>)
            },
            header: () => 'Created At',
        }),

        columnHelper.accessor(row => row, {
            id: 'mobile',

            cell: info => {
                const row = info.getValue()
                return (<CRow>
                    <CCol xs={12} style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                        <span className='lead'>#{row.id} {row.user?.name} (Group {row.group})</span>
                    </CCol>
                    <CCol xs={12}>
                        <span className='text-nowrap'>Creater at : {moment(row.created_at).format("DD MMM YYYY")}</span>
                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',

        })
    ], [columnHelper])

    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData({ ...onfulfilled.data, data: [...data.data, ...onfulfilled.data.data] })
        }).catch(reason => {
            addToast({ message: reason.message, color: "danger" })
        });
    };

    useEffect(() => {
        setloading(true)
        api.get(`/respondent?${searchParams.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            addToast({ message: reason.message, color: "danger" })
        });
    }, [addToast, searchParams])

    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )

    return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
        >
            <ComponenetTable
                data={data?.data || []}
                columns={columns}
                responsive
                // footerVisible
                onclickRow={(original: IRespondent) => {
                    navigate({ pathname: `/admin/respondent/detail/${original.id}` })
                }}
            />
        </InfiniteScroll>
    </>);
};

export default Table;