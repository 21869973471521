import {useContext, useEffect, useState} from "react";
import {Context} from "../../../../../context";
import useToast from "../../../../../hooks/toast";
import {z} from "zod";
import {
    CButton,
    CCard,
    CCardBody, CCardFooter,
    CCardHeader,
    CCardTitle, CCol, CForm, CFormCheck, CFormLabel, CLoadingButton, CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableRow
} from "@coreui/react-pro";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";
import Page4 from "./page4";
import Page5 from "./page5";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import CIcon from "@coreui/icons-react";
import {cilCaretLeft, cilCaretRight} from "@coreui/icons-pro";
import api from "../../../../api";
import {GenericErrorHandler} from "../../../../../helpers";


export default function MengukurKuesioner(){
    const [page, setPage] = useState(1)
    const { reloadQuestionnaire } = useContext(Context);
    const [addToast] = useToast()
    const FormSchema = z.object({
        q1: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q2: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q3: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q4: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q5: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q6: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q7: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q8: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q9: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q10: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q11: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q12: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q13: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q14: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q15: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q16: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q17: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q18: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q19: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q20: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q21: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q22: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q23: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
        q24: z.string({message: 'Pilih salah satu'}).min(1, 'Pilih salah satu'),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        getValues,
        control,
        reset,
        watch,
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: { ...JSON.parse(localStorage.getItem('mengukurkuesioner') || "{}")}
    })

    const instance = watch()

    useEffect(() => {
        localStorage.setItem('mengukurkuesioner', JSON.stringify(instance))
    }, [instance])


    function Question({question, name}: {question: string, name : ("q1" | "q2" | "q3" | "q4" | "q5" | "q6" | "q7" | "q8" | "q9" | "q10" | "q11" | "q12" | "q13" | "q14" | "q15" | "q16" | "q17" | "q18" | "q19" | "q20" | "q21" | "q22" | "q23" | "q24")}) {
        return (<>
            <p>{question}</p>
            <CTable>
                <CTableBody>
                    <CTableRow>
                        <CTableDataCell>
                            <CFormCheck
                                label={'Sangat Setuju'}
                                id={`${name}_1`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, {required: true})}
                                type={"radio"}
                                value={1}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormCheck
                                label={'Setuju'}
                                id={`${name}_2`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, {required: true})}
                                type={"radio"}
                                value={2}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormCheck
                                label={'Netral'}
                                id={`${name}_3`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, {required: true})}
                                type={"radio"}
                                value={3}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormCheck
                                label={'Tidak Setuju'}
                                id={`${name}_4`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, {required: true})}
                                type={"radio"}
                                value={4}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormCheck
                                label={'Sangat Tidak Setuju'}
                                id={`${name}_5`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, {required: true})}
                                type={"radio"}
                                value={5}
                            />
                        </CTableDataCell>
                    </CTableRow>
                    {/*<CTableRow>*/}
                    {/*    <CTableDataCell colSpan={5} className={'is-invalid'}>*/}
                    {/*        <div className="invalid-feedback">Nama tidak boleh kosong</div>*/}
                    {/*    </CTableDataCell>*/}
                    {/*</CTableRow>*/}
                </CTableBody>
            </CTable>
        </>)
    }

    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
        console.log(errors)

        await api.post('/answer', {section : 'mengukurkuesioner', answer : JSON.stringify(instance)}).then(onfulfilled => {
            addToast({message: 'Section submitted', color: 'success'})
            localStorage.removeItem('mengukurkuesioner')
            reloadQuestionnaire()
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }

    useEffect(() => {
        console.log(errors)
    }, [errors]);

    return (<CCard>
        <CCardBody>
            <CCardTitle>
                <CRow>
                    <CCol>Kuesioner Penelitian ({page}/5)</CCol>
                    <CCol className={'d-flex justify-content-end'}>
                        <CButton disabled={page <= 1} size={"sm"} onClick={() => {
                            setPage(x => {
                                const newpage = x - 1;
                                return newpage <= 1 ? 1 : newpage;
                            })
                        }} className={'ms-2'}><CIcon icon={cilCaretLeft}/></CButton>
                        <CButton disabled={page >= 5} size={"sm"} onClick={() => {
                            setPage(x => {
                                const newpage = x + 1;
                                return newpage >= 5 ? 5 : newpage;
                            })
                        }} className={'ms-2'}><CIcon icon={cilCaretRight}/></CButton>
                    </CCol>
                </CRow>
            </CCardTitle>
            <p>Pilihlah salah satu pointer yang menyatakan keterwakilan pendapat anda terhadap setiap pernyataan
                berikut.</p>
            <CForm
                id='formSubmit'
                className="row"
                autoComplete='false'
                onSubmit={handleSubmit(onSubmit)}
            >
                <CRow>
                    <CCol>
                        {page === 1 && <Page1 Question={Question}/>}
                        {page === 2 && <Page2 Question={Question}/>}
                        {page === 3 && <Page3 Question={Question}/>}
                        {page === 4 && <Page4 Question={Question}/>}
                        {page === 5 && <Page5 Question={Question}/>}
                    </CCol>
                </CRow>
            </CForm>
            <div className={'d-flex justify-content-end'}>
                <CButton className={'ms-2'} color={'danger'} onClick={() => {
                    reset()
                }}>Reset</CButton>
                {page >= 5 && <CLoadingButton disabled={isSubmitting} loading={isSubmitting} type={'submit'} form={'formSubmit'} className={'ms-2'}>Selanjutnya</CLoadingButton>}
            </div>
        </CCardBody>
    </CCard>)
}