import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCardTitle,
    CImage,
    CLoadingButton
} from "@coreui/react-pro";
import React, {useContext, useEffect, useState} from "react";
import useToast from "../../../hooks/toast";
import api from "../../api";
import {Context} from "../../../context";
import ThankYou from'../../../assets/thankyou.svg'

export default function Finished(){
    const { setVisibleModalLogin } = useContext(Context);
    const [loading, setLoading] = useState(false)
    const [addToast] = useToast()
    useEffect(() => {
        localStorage.removeItem('informasidiri')
        localStorage.removeItem('mengukurkuesioner')
        localStorage.removeItem('case')
    }, []);

    return (<CCard>
        <CCardBody>
            <CCardTitle>Selesai</CCardTitle>
            <p>Terimakasih telah meluangkan waktu untuk mengisi quesioner ini. Salam sehat dan sukses selalu. 🙂</p>
            <div className={'d-flex justify-content-center'}>
                <CImage src={ThankYou} className={'mw-100'} width={400} />
            </div>

            <div className={'d-flex justify-content-end'}>
                <CLoadingButton loading={loading} color='danger'
                    onClick={() => {
                        setLoading(true)
                        api.get(`/auth/logout`).then(onfulfilled => {

                        }).finally(() => {
                            addToast({ color: "success", message: "Logout berhasil" })
                            localStorage.clear()
                            setLoading(false)
                            setVisibleModalLogin(true)
                        })
                    }}
                >
                    Keluar
                </CLoadingButton>
            </div>
        </CCardBody>
    </CCard>)
}