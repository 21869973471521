
import {
    CButton,
    CCollapse,
    CContainer,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CForm,
    CFormInput,
    CHeader,
    CHeaderNav,
    CHeaderToggler, CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CNav,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavbarText,
    CNavbarToggler,
    CNavItem,
    CNavLink,
} from '@coreui/react-pro'
import React, {useContext, useState} from 'react'
import {cilAccountLogout, cilMenu} from '@coreui/icons-pro'

import {Link, useNavigate} from "react-router-dom";

import CIcon from "@coreui/icons-react";
import api from "../../admin/api";
import useToast from "../../hooks/toast";
import {Context} from "../../context";

const Header = () => {
    const [showModalLogout, setshowModalLogout,] = useState(false)
    const [visible, setVisible] = useState(false)
    const { setVisibleModalLogout, user  } = useContext(Context)

    return (
        <CHeader position="sticky" className="mb-4">
            <CNavbar colorScheme="light" expand="md" className="w-100 ">
                <CContainer fluid>
                    <CNavbarBrand href="#">{process.env.REACT_APP_PUBLIC_SITE_NAME}</CNavbarBrand>
                    <CNavbarToggler
                        aria-label="Toggle navigation"
                        aria-expanded={visible}
                        onClick={() => setVisible(!visible)}
                    />
                    <CCollapse className="navbar-collapse" visible={visible}>
                        <CNavbarNav  className="me-auto">
                            {/*<CNavItem>*/}
                            {/*    <Link className={"nav-link"} to={{pathname : '/petunjuk'}}>Petunjuk</Link>*/}
                            {/*</CNavItem>*/}
                            {/*<CNavItem>*/}
                            {/*    <Link className={"nav-link"} to={{pathname : '/pertanyaan'}}>Pertanyaan</Link>*/}
                            {/*</CNavItem>*/}
                            <CNavItem>
                                <span className={'nav-link'}>{user?.email}</span>
                            </CNavItem>
                        </CNavbarNav>
                        <CNavbarNav className="d-flex">

                            <CNavItem>
                                <CNavLink
                                    className='text-danger'
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => {
                                        setVisibleModalLogout(true)
                                    }}
                                >
                                    <CIcon icon={cilAccountLogout} className="me-2" />
                                    Keluar
                                </CNavLink>
                            </CNavItem>
                        </CNavbarNav>
                    </CCollapse>
                </CContainer>
            </CNavbar>
        </CHeader>
    )
}


export default React.memo(Header)