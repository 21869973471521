
import {Route} from "react-router-dom";
import React from "react";
import Error404 from "./Error404";
import App from "./App";
import {IRoute} from "../types/layout";
import RespondentIndex from "./pages/respondent";
import DespondentDetail from "./pages/respondent/detail";
import BackupIndex from "./pages/backup";

const routes: IRoute[] = [
    { path: `/`, name: 'Respondent', element: <RespondentIndex /> },
    { path: `/respondent/detail/:id`, name: 'Detail', element: <DespondentDetail /> },
    { path: `/respondent`, name: 'Respondent', element: <RespondentIndex /> },
    { path: `/backup`, name: 'Backup', element: <BackupIndex /> },
]

const AdminRoutes = [
    <Route path="/admin" element={
        <React.StrictMode><App /></React.StrictMode>}>
        {routes.map((route, key) => {
            return (route.element && <Route
                key={key}
                path={`/admin${route.path}`}
                element={route.element}
            />)
        })}
        <Route path="*" element={<Error404 />} />
    </Route>
];

export default AdminRoutes