import React, {Component, useContext, useEffect} from 'react';
import {

    CModal,
    CModalBody,
    CRow, CSpinner
} from "@coreui/react-pro";

import useToast from "../../hooks/toast";
import {Context} from "../../context";

export function ModelRefresh(){

    const { visibleModalRefresh,  } = useContext(Context)

    return(<CModal portal backdrop={'static'} size='sm' alignment='center' visible={visibleModalRefresh}>
        <CModalBody >
            <div className={'text-center'}>
                <span><CSpinner size={"sm"}/> Refresh token...</span>
            </div>
        </CModalBody>
    </CModal>)
}